window.game_carousel = function (options = {}) {
    return {
        factor: 8,
        delay: 0,
        invert: 0,
        invert: false,
        jq: false, container: false, height: false, options: options,
        init() {
            this.factor = (typeof (this.options['factor']) != 'undefined') ? this.options['factor'] : this.factor;
            this.delay = (typeof (this.options['delay']) != 'undefined') ? this.options['delay'] : this.delay;
            this.offset = (typeof (this.options['offset']) != 'undefined') ? this.options['offset'] : this.offset;
            this.invert = (typeof (this.options['invert']) != 'undefined') ? this.options['invert'] : this.invert;

            this.jq = $(this.$el);
            this.height = this.jq.outerHeight();
            this.container = this.jq.find('.games');

            if (this.invert)
                this.move(false);

            setTimeout(() => this.move(), this.delay);
        },
        move(animate = true) {
            const current = parseInt(this.container.css('top'), 10);

            const height = this.container.outerHeight();
            const distance = this.height - height;
            const top = (Math.abs(current) > 10) ? 0 : distance;
            const duration = Math.abs(distance * this.factor);

            if (animate)
                this.container.animate({ top: top }, duration, () => this.move());
            else
                this.container.css('top', top + 'px');
        }
    }
}

window.animate = function (duration = 1000, delay = 0, use_exit = false) {
    return {
        id: false, jq: false, duration: duration, delay: delay, prepend: false, use_exit: use_exit,
        iclass: false, eclass: false, istyle: false, estyle: false, waypoint:false,
        init() {
            this.jq = $(this.$el);
            this.jq.attr('id', this.id);
            this.prepend = 'transition-delay: '+this.delay+'ms;transition-duration: '+this.duration+'ms;';
            this.jq.attr('style', this.prepend + this.jq.data('init'));

            this.iclass = (typeof (this.jq.data('init-class')) != 'undefined')
                ? this.jq.data('init-class')
                : '';
            this.eclass = (typeof (this.jq.data('enter-class')) != 'undefined')
                ? this.jq.data('enter-class')
                : '';

            this.istyle = (typeof (this.jq.data('init')) != 'undefined')
                ? this.jq.data('init')
                : '';
            this.estyle = (typeof (this.jq.data('enter')) != 'undefined')
                ? this.jq.data('enter')
                : '';

            this.jq.data('init');
            this.jq.addClass("animate");

            this.jq.removeClass(this.iclass);
            this.jq.addClass(this.iclass);

        },
        enter()
        {
            this.jq.removeClass(this.iclass);
            this.jq.addClass(this.eclass);
            this.jq.attr('style', this.prepend + this.estyle);
        },
        exit()
        {
            this.jq.removeClass(this.eclass);
            this.jq.addClass(this.iclass);
            this.jq.attr('style', this.istyle);
        },
		animate:
		{
			['x-on:all-images-loaded.window']()
			{
                if(this.use_exit)
                {
                    this.waypoint = new Waypoint.Inview({
                        element: this.jq[0],
                        enter: () => { this.enter(); },
                        exited: () => { this.exit(); },
                    });
                }
                else
                {
                    this.waypoint = new Waypoint({
                        element: this.jq[0],
                        offset: '100%',
                        handler: () => { this.enter(); this.waypoint.destroy(); this.waypoint = false; },
                    });
                }
			},
		}
    }
}